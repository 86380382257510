import React from 'react'
import ProjectItem from '../common/ProjectItem'

const SelectedProjectsContainer = ({ listOfProjectNodes }) => {
  const renderProjects = projects => {
    return projects.map(project => (
      <ProjectItem key={project.node.title} project={project.node} />
    ))
  }

  return (
    <section id="selected-projects" className="projects-list">
      <div className="container">
        <p className="seo-h1 text-lowercase text-center">ausgewähltes projekt</p>
        <div className="row">{renderProjects(listOfProjectNodes)}</div>
      </div>
    </section>
  )
}

export default SelectedProjectsContainer
